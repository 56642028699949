@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark {
  @apply bg-neutral-900 text-zinc-200;
}

.light {
  @apply bg-zinc-200 text-black;
}

.mainhvr {
  @apply text-teal-500 hover:text-teal-800 focus:text-ts-1 transition-colors duration-300 ease-in-out
  
}

.navhvr {
 @apply hover:text-teal-500
}
